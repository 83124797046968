.outerWrapper {
  margin: auto 0px 0px;
  position: relative;
}

.errorBox {
  position: absolute;
  height: 134px;
  background-color: white;
  top: -134px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.discountErrorBox {
  height: 152px;
  top: -152px;
}

.financeErrorBox {
  height: 170px;
  top: -170px;
}

.discountFinanceErrorBox {
  height: 186px;
  top: -186px;
}

.redText {
  color: var(--color-red-700);
}

.errorBoxTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--neutral-100, #e6e6e6);
  margin: 0 -12px 0;
  padding: var(--spacing-3) 0 var(--spacing-3);
  gap: var(--spacing-2);
}

.seeNumber {
  @media (--tablet-s) {
    display: none;
  }
}

.number {
  display: none;
  @media (--tablet-s) {
    display: flex;
  }
}
