.wrapper {
  & h2 {
    @media (--tablet-l) {
      font-size: 32px;
      margin-bottom: var(--spacing-8);
    }
    @media (--desktop) {
      margin-bottom: var(--spacing-10);
    }
  }

  & h6 {
    margin: 0;
  }
}

.layout {
  width: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
}

.brands {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: var(--spacing-2);

  @media (--tablet) {
    gap: var(--spacing-3);
  }

  @media (--tablet-l) {
    gap: var(--spacing-4);
  }

  @media (--desktop) {
    gap: var(--spacing-5);
  }

  & a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }
}

.brand {
  scroll-snap-align: flex-start;
  flex: 1 1 50px;
  border: 1px solid var(--color-tarmac-grey-500);
  height: 142px;
  width: 132px;
  padding: var(--spacing-2) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  text-decoration: none;
  transition: 0.1s;
  gap: 0;

  @media (--tablet) {
    width: 170px;
    height: 178px;
    padding: var(--spacing-4) 0;
  }

  @media (--tablet-l) {
    height: 199px;
    width: 228px;
  }

  @media (--desktop) {
    aspect-ratio: 1/1;
    width: unset;
  }

  &:hover {
    border-color: var(--color-tarmac-grey-700);
  }
}

.brandName {
  margin: 0;
  padding: 0 5px;
  text-align: center;
  margin-top: auto;

  font-size: 16px;
  line-height: 1.3;

  @media (--tablet) {
    font-size: inherit;
  }
}

.image {
  height: 70px;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  margin-top: auto;

  @media (--tablet) {
    height: 80px;
    margin-bottom: 15px;
  }
}
